import './App.css';
import Card from './Components/Card';



function App() {
  
  

  return (
    <div className='App'>
      <header className='App-header' >
        <Card />
        <h1> Seja bem vindo </h1>
      </header>
    </div>
  );
}

export default App;
